<template>
    <b-modal v-model="isModalActive" :on-close="closeModal()">
        <div class="box" style="min-width: 450px;">
                <b-tabs position="is-centered" @input="initCityInfo()">
                    <b-tab-item :label="backmeta.cities[cityId].nameObject[currentLanguage]" icon="city">
                        <div v-if="cities[cityId]" class="columns is-multiline is-centered is-mobile">
                            <div class="column is-half-desktop is-half-tablet is-full-mobile">
                                <City :cityId="cityId" :inModal="true"/>
                            </div>
                            <div class="column is-half-desktop is-half-tablet is-full-mobile mt-5">
                                <h2 class="title has-text-centered">{{ tt('CityMarket') }}</h2>
                                <p class="subtitle has-text-centered">{{ tt('CityMarketInfo') }}</p>
                            </div>
                            <div
                                v-for="(marketItem, marketSlug) in cities[cityId].market"
                                :key="marketSlug"
                                class="column is-one-quarter-desktop is-one-quarter-tablet is-half-mobile"
                            >
                                <div class="box p-1">
                                    <h5 class="has-text-centered title is-5 mb-2">
                                        <Emoji class="mr-1" :utf="goods[marketSlug].emoji" size="20px" />
                                        <span  style="vertical-align: 20%">{{ goods[marketSlug].name[currentLanguage] }}</span>
                                    </h5>
                                    <div class="level is-mobile">
                                        <div class="level-left">
                                            <div class="level-item" :title="tt('CitySellPrice')" style="display: inline-block;">
                                                <Emoji utf="📤" size="18px" class="mr-1"/>
                                                <strong style="vertical-align: 15%">{{ marketItem.sell > 0 ? ('+' + marketItem.sell) : ' - - '}}</strong>
                                                <Emoji utf="💰" size="20px"/>
                                            </div>
                                        </div>
                                        <div class="level-right">
                                            <div class="level-item" :title="tt('CityBuyPrice')" style="display: inline-block;">
                                                <Emoji utf="📥" size="18px" class="mr-1"/>
                                                <strong style="vertical-align: 15%">{{ marketItem.buy > 0 ? ('-' + marketItem.buy) : ' - - '}}</strong>
                                                <Emoji utf="💰" size="20px"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab-item>

                    <b-tab-item :label="tt('Contracts')" icon="script-text-outline">
                        <div v-if="contracts[cityId] && contracts[cityId][1]" class="columns is-multiline is-centered is-mobile">
                            <div
                                v-for="(cityContract, contractId) in contracts[cityId]"
                                :key="contractId"
                                class="column is-one-third-desktop is-one-third-tablet is-full-mobile"
                            >
                                <div class="box p-2">
                                    <h5 class="title is-6 mb-1">{{ cityId }}.{{ contractId }} {{ cityContract.title[currentLanguage] }}</h5>
                                    <p class="has-text-centered">
                                        <Emoji utf="🤝" size="18px"/>
                                        <strong style="vertical-align: 15%">{{ cityContract.reputation }} </strong>
                                        <Emoji :utf="cityIcon" size="18px"/>
                                        <strong style="vertical-align: 15%">{{ cityContract.minCityRep }}-{{ cityContract.maxCityRep }} </strong>
                                        <Emoji v-if="cityContract.repeat" utf="🔁" size="18px"/>
                                    </p>
                                    <p class="mb-1">{{ cityContract.text[currentLanguage] }}</p>
                                    <p v-if="cityContract.prize" class="has-text-centered">
                                        <strong> +{{ cityContract.prize.reputation }}🤝 +{{ cityContract.prize.gold }}💰</strong>
                                    </p>
                                    <p v-if="cityContract.prize_transport" class="has-text-centered">
                                        <strong style="vertical-align: 15%"> + </strong>
                                        <Emoji :utf="backmeta.transport[cityContract.prize_transport].icon" size="18px"/>
                                        <strong style="vertical-align: 15%"> {{ backmeta.transport[cityContract.prize_transport].name[currentLanguage] }}</strong>
                                    </p>
                                    <p v-if="cityContract.prize_autopilot" class="has-text-centered">
                                        <strong style="vertical-align: 15%"> + </strong>
                                        <Emoji utf="🤖" size="18px"/>
                                        <strong style="vertical-align: 15%">{{ tt('AutopilotInstall') }} lvl {{ cityContract.prize_autopilot.level }}</strong>
                                    </p>
                                    <p v-if="cityContract.prize_chassis" class="has-text-centered">
                                        <strong style="vertical-align: 15%"> + </strong>
                                        <Emoji utf="🛹" size="18px"/>
                                        <strong style="vertical-align: 15%">{{ tt('ChassisInstall') }} {{ cityContract.prize_chassis * 100 }}%</strong>
                                    </p>
                                    <p v-if="cityContract.prize_tires" class="has-text-centered">
                                        <strong style="vertical-align: 15%"> + </strong>
                                        <Emoji utf="🥾" size="18px"/>
                                        <strong style="vertical-align: 15%">{{ tt('TiresInstall') }} lvl {{ cityContract.prize_tires * 100 }}%</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p v-else class="is-size-4 has-text-centered">{{ tt('CityEmptyContracts') }}</p>
                    </b-tab-item>

                    <b-tab-item :label="tt('Info')" icon="information" v-if="cities[cityId] && cities[cityId].info.length > 0">
                        <button @click="prevCityInfo()" class="button is-info is-rounded" style="position: absolute; top: 250px; left: 40px;">
                            <b-icon
                                icon="chevron-left"
                                size="is-large"
                            />
                        </button>
                        <button @click="nextCityInfo()" class="button is-info is-rounded" style="position: absolute; top: 250px; right: 40px;">
                            <b-icon
                                icon="chevron-right"
                                size="is-large"
                            />
                        </button>
                        <div ref="cityInfoBlock" style="min-height: 300px;"></div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </b-modal>
</template>

<script>
    import { mapState } from 'vuex';
    // import Vue from 'vue';
    import tt from './../plugins/tt.js';
    import Emoji from './../widgets/Emoji.vue';
    import City from './City.vue';

    export default {
        components: {
            Emoji,
            City,
        },
        data () {
            return {
                tt: tt,
                currentLanguage: 'ua',
                cityId: 1,
                currentCityInfoIndex: 0,
                currentCityInfo: null,
                isModalActive: false,
            }
        },
        computed: mapState({
            backmeta: 'backmeta',
            cityModalId: 'cityModalId',
            cities: 'ws.Cities',
            cityIcon: 'ws.CityIcon',
            contracts: 'ws.Contracts',
            goods: 'ws.Goods',
            user: 'ws.User',
        }),
        watch: {
            cities: function () {
                this.currentCityInfo = this.cities[this.cityId].info[0] || null;
            },
            cityModalId: function (newCityModalId) {
                if (newCityModalId === null) {
                    this.isModalActive = false;
                    return;
                }

                this.cityId = newCityModalId;
                this.currentCityInfoIndex = 0;
                this.currentCityInfo = this.cities[this.cityId].info[0] || null;
                this.isModalActive = true;
                this.initCityInfo();
            }
        },
        created () {
            this.currentLanguage = localStorage.getItem('lang') || 'ua';
        },
        methods: {
            closeModal() {
                if (this.cityId === this.cityModalId && !this.isModalActive) {
                    this.$store.commit('openCityModal', null);
                }
            },
            prevCityInfo() {
                this.currentCityInfoIndex--;
                if (this.currentCityInfoIndex < 0) {
                    this.currentCityInfoIndex = this.cities[this.cityId].info.length - 1;
                }

                this.currentCityInfo = this.cities[this.cityId].info[this.currentCityInfoIndex] || null;
                this.initCityInfo();
            },
            nextCityInfo() {
                this.currentCityInfoIndex++;
                if (this.currentCityInfoIndex >= this.cities[this.cityId].info.length) {
                    this.currentCityInfoIndex = 0;
                }

                this.currentCityInfo = this.cities[this.cityId].info[this.currentCityInfoIndex] || null;
                this.initCityInfo();
            },
            initCityInfo() {
                if (this.$refs.cityInfoBlock && this.currentCityInfo) {
                    this.$refs.cityInfoBlock.innerHTML = '';
                } else {
                    return;
                }

                const tgParts = this.currentCityInfo.split('t.me/');
                const ytParts = this.currentCityInfo.split('youtube.com/watch?v=');
                if (tgParts[1] || null) {
                    const tgInfoPost = document.createElement('script');
                    tgInfoPost.src = 'https://telegram.org/js/telegram-widget.js?22';
                    tgInfoPost.setAttribute('data-telegram-post', tgParts[1]);
                    tgInfoPost.setAttribute('data-width', '100%');
                    tgInfoPost.setAttribute('data-userpic', 'false');
                    this.$refs.cityInfoBlock.appendChild(tgInfoPost);
                } else if (ytParts[1] || null) {
                    const ytInfoPost = document.createElement('iframe');
                    ytInfoPost.src = 'https://www.youtube.com/embed/' + ytParts[1];
                    ytInfoPost.setAttribute('width', '100%');
                    ytInfoPost.setAttribute('height', '500');
                    this.$refs.cityInfoBlock.appendChild(ytInfoPost);
                } else {
                    this.$refs.cityInfoBlock.innerHTML = this.currentCityInfo;
                }
            }
        }
    }
</script>
