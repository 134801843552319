export const backmeta = {
    "transport": {
        "zaz": {
            "icon": "🚗",
            "name": {
                "ua": "ЗАЗ Запорожець",
                "en": "ZAZ Zaporozhets",
                "ru": "ЗАЗ Запорожец"
            },
            "text": {
                "ua": "ЗАЗ-968 - легковий автомобіль, що виготовлявся на Запорізькому автомобільному заводі з 1960 по 1994 рік. Загалом було випущено близько 3,5 мільйонів автомобілів цієї моделі.",
                "en": "ZAZ-968 is a passenger car produced at the Zaporizhzhia Automobile Plant from 1960 to 1994. A total of about 3.5 million cars of this model were produced.",
                "ru": "ЗАЗ-968 - легковой автомобиль, выпускавшийся на Запорожском автомобильном заводе с 1960 по 1994 год. Всего было выпущено около 3,5 миллионов автомобилей этой модели."
            },
            "cargo": 5,
            "tank": 40,
            "rate100": 11,
            "hpRate": 5,
            "speed": 118
        },
        "luaz": {
            "icon": "🛻",
            "name": {
                "ua": "ЛуАЗ Волинянка",
                "en": "LuAZ Volynyanka",
                "ru": "ЛуАЗ Волынянка"
            },
            "text": {
                "ua": "ЛуАЗ-969М - повноприводний автомобіль, що виготовлявся на Луцькому автомобільному заводі з 1979 по 1992 рік. В народі відомий як «Волинянка».",
                "en": "LuAZ-969M is a four-wheel drive vehicle produced from 1979 to 1992 at the Lutsk Automobile Plant. Known as \"Volynyanka\" in the people.",
                "ru": "ЛуАЗ-969М - полноприводный автомобиль, выпускавшийся на Луцком автомобильном заводе с 1979 по 1992 год. В народе известен как «Волынянка»."
            },
            "cargo": 10,
            "tank": 37,
            "rate100": 8,
            "hpRate": 3,
            "speed": 90
        },
        "pie": {
            "icon": "🚙",
            "name": {
                "ua": "ЗАЗ Пиріжок",
                "en": "ZAZ Pie",
                "ru": "ЗАЗ Пирожок"
            },
            "text": {
                "ua": "Пікап Запорізького автомобілебудівного заводу, з оригінальною конструкцією кузова, що нагадує пиріжок.",
                "en": "A pickup truck from the Zaporizhzhia Automobile Plant, with an original body design that resembles a pie.",
                "ru": "Пикап Запорожского автомобилестроительного завода, с оригинальной конструкцией кузова, напоминающей пирожок."
            },
            "cargo": 10,
            "tank": 48,
            "rate100": 11,
            "hpRate": 5,
            "speed": 130
        },
        "minibus": {
            "icon": "🚐",
            "name": {
                "ua": "Мінібус",
                "en": "Minibus",
                "ru": "Минибус"
            },
            "text": {
                "ua": "Малий вантажний транспорт, який використовується для перевезення товарів.",
                "en": "Small cargo vehicle used to transport goods.",
                "ru": "Малый грузовой транспорт, который используется для перевозки товаров."
            },
            "cargo": 15,
            "tank": 60,
            "rate100": 11,
            "hpRate": 6,
            "speed": 110
        },
        "maxibus": {
            "icon": "🚌",
            "name": {
                "ua": "Максібус",
                "en": "Maxibus",
                "ru": "Максибус"
            },
            "text": {
                "ua": "Великий вантажний транспорт, який використовується для перевезення великих обсягів товарів.",
                "en": "Large cargo vehicle used to transport large volumes of goods.",
                "ru": "Большой грузовой транспорт, который используется для перевозки больших объемов товаров."
            },
            "cargo": 20,
            "tank": 70,
            "rate100": 11,
            "hpRate": 8,
            "speed": 130
        },
        "sprinter": {
            "icon": "🚚",
            "name": {
                "ua": "Mercedes Sprinter",
                "en": "Mercedes Sprinter",
                "ru": "Mercedes Sprinter"
            },
            "text": {
                "ua": "Великий вантажний транспорт, який використовується для перевезення великих обсягів товарів швидко та комфортно.",
                "en": "Large cargo vehicle used to transport large volumes of goods quickly and comfortably.",
                "ru": "Большой грузовой транспорт, который используется для перевозки больших объемов товаров быстро и комфортно."
            },
            "cargo": 25,
            "tank": 75,
            "rate100": 9,
            "hpRate": 9,
            "speed": 150
        },
        "kraz": {
            "icon": "🚛",
            "name": {
                "ua": "КрАЗ",
                "en": "KrAZ",
                "ru": "КрАЗ"
            },
            "text": {
                "ua": "Великий вантажний транспорт, який використовується для перевезення великих обсягів важких товарів.",
                "en": "Large cargo vehicle used to transport large volumes of heavy goods.",
                "ru": "Большой грузовой транспорт, который используется для перевозки больших объемов тяжелых товаров."
            },
            "cargo": 35,
            "tank": 330,
            "rate100": 30,
            "hpRate": 3,
            "speed": 100
        }
    },
    "achievements": {
        "earn_rep": {
            "icon": "🤝",
            "name": {
                "ua": "напрацьована репутація",
                "en": "accumulated reputation",
                "ru": "наработанная репутация"
            },
            "text": {
                "1": {
                    "ua": "виконуйте контракти в містах та заробляйте репутацію",
                    "en": "complete contracts in cities and earn reputation",
                    "ru": "выполняйте контракты в городах и зарабатывайте репутацию"
                }
            },
            "levels": {
                "1": 50,
                "2": 100,
                "3": 200,
                "4": 400,
                "5": 8000
            }
        },
        "mechanic": {
            "icon": "👨‍🔧",
            "name": {
                "ua": "механік",
                "en": "mechanic",
                "ru": "механик"
            },
            "delay": "+ 1 MINUTES",
            "text": {
                "1": {
                    "ua": "відкривайте новий транспорт та встановлюйте модифікації: автопілот, покращені шини та шасі",
                    "en": "open new transport and install modifications: autopilot, improved tires and chassis",
                    "ru": "открывайте новый транспорт и уставливайте модификации: автопилот, улучшенные шины и шасси"
                }
            },
            "levels": {
                "1": 3,
                "2": 5,
                "3": 10,
                "4": 20,
                "5": 50
            }
        },
        "ride_km": {
            "icon": "👣",
            "name": {
                "ua": "загальний пробіг км",
                "en": "total ride km",
                "ru": "общий пробег км"
            },
            "levels": {
                "1": 300,
                "2": 1000,
                "3": 10000,
                "4": 100000,
                "5": 1000000
            }
        },
        "city_tourist": {
            "icon": "🌇",
            "name": {
                "ua": "міський турист",
                "en": "city tourist",
                "ru": "городской турист"
            },
            "text": {
                "1": {
                    "ua": "відвідайте усі міста та дізнайтеся більше про них",
                    "en": "visit all cities and learn more about them",
                    "ru": "посетите все города и узнайте больше о них"
                },
                "2": {
                    "ua": "відвідайте усі міста по два рази",
                    "en": "visit all cities twice",
                    "ru": "посетите все города по два раза"
                },
                "3": {
                    "ua": "відвідайте усі міста по чотири рази",
                    "en": "visit all cities four times",
                    "ru": "посетите все города по четыре раз"
                },
                "4": {
                    "ua": "відвідайте усі міста по вісім разів",
                    "en": "visit all cities eight times",
                    "ru": "посетите все города по восемь раз"
                },
                "5": {
                    "ua": "відвідайте усі міста по шістнадцять разів",
                    "en": "visit all cities sixteen times",
                    "ru": "посетите все города по шестнадцать раз"
                }
            },
            "delay": "+ 10 MINUTES",
            "unique": 18,
            "levels": {
                "1": 1,
                "2": 2,
                "3": 4,
                "4": 8,
                "5": 16
            }
        },
        "bla_bla": {
            "icon": "🗣️",
            "name": {
                "ua": "бла-бла",
                "en": "bla-bla",
                "ru": "бла-бла"
            },
            "text": {
                "1": {
                    "ua": "пишіть повідомлення на карті в своєму транспорті для інших гравців, враховуються тільки раз в 10 хвилин",
                    "en": "write messages on the map in your transport for other players, only once every 10 minutes are taken into account",
                    "ru": "пишите сообщения на карте в своём транспорте для других игроков, учитываються только раз в 10 минут"
                }
            },
            "delay": "+ 10 MINUTES",
            "levels": {
                "1": 10,
                "2": 20,
                "3": 50,
                "4": 300,
                "5": 1000
            }
        },
        "explorer": {
            "icon": "🌌",
            "name": {
                "ua": "мандрівник",
                "en": "explorer",
                "ru": "путешественник"
            },
            "text": {
                "1": {
                    "ua": "проїдьте усі маршрути та дізнайтеся більше про них",
                    "en": "drive all routes and learn more about them",
                    "ru": "проезжайте все маршруты и узнайте больше о них"
                },
                "2": {
                    "ua": "проїдьте усі маршрути по два рази",
                    "en": "drive all routes twice",
                    "ru": "проезжайте все маршруты по два раза"
                },
                "3": {
                    "ua": "проїдьте усі маршрути по чотири рази",
                    "en": "drive all routes four times",
                    "ru": "проезжайте все маршруты по четыре раз"
                },
                "4": {
                    "ua": "проїдьте усі маршрути по вісім разів",
                    "en": "drive all routes eight times",
                    "ru": "проезжайте все маршруты по восемь раз"
                },
                "5": {
                    "ua": "проїдьте усі маршрути по шістнадцять разів",
                    "en": "drive all routes sixteen times",
                    "ru": "проезжайте все маршруты по шестнадцать раз"
                }
            },
            "delay": "+ 10 MINUTES",
            "unique": 39,
            "levels": {
                "1": 1,
                "2": 2,
                "3": 4,
                "4": 8,
                "5": 16
            }
        },
        "fashionable": {
            "icon": "💄",
            "name": {
                "ua": "фешенебельний",
                "en": "fashionable",
                "ru": "фешенебельный"
            },
            "text": {
                "1": {
                    "ua": "бронюйте нові кольори для транспорту на карті, враховуються тільки раз в 10 хвилин",
                    "en": "book new colors for transport on the map, only once every 10 minutes are taken into account",
                    "ru": "бронируйте новые цвета для транспорт на карте, учитываються только раз в 10 минут"
                }
            },
            "delay": "+ 10 MINUTES",
            "levels": {
                "1": 10,
                "2": 20,
                "3": 50,
                "4": 300,
                "5": 1000
            }
        },
        "everyday_rider": {
            "icon": "📆",
            "name": {
                "ua": "кожен день у дорозі",
                "en": "every day rider",
                "ru": "каждый день в дороге"
            },
            "text": {
                "1": {
                    "ua": "кожен день здійснюйте поїздку на транспорті",
                    "en": "ride transport every day",
                    "ru": "каждый день совершайте поездку на транспорте"
                }
            },
            "levels": {
                "1": 3,
                "2": 7,
                "3": 14,
                "4": 30,
                "5": 90
            }
        }
    },
    "cities": {
        "1": {
            "id": "1",
            "lat": "50.450393",
            "lon": "30.524554",
            "nameObject": {
                "ua": "Київ",
                "en": "Kyiv",
                "ru": "Киев"
            },
            "aboutObject": {
                "ua": "Столиця та найбільше місто України. Розташований у середній течії Дніпра, у північній Наддніпрянщині. Політичний, соціально-економічний, транспортний, освітньо-науковий, історичний, культурний та духовний центр України.",
                "en": "The capital and largest city of Ukraine. It is located in the middle course of the Dnieper, in the northern Naddnipriyan region. Political, socio-economic, transport, educational-scientific, historical, cultural and spiritual center of Ukraine.",
                "ru": "Столица и самый большой город Украины. Расположен на реке Днепр, в северном Приднепровье. Политический, социально-экономический, транспортный, образовательно-научный, исторический, культурный и духовный центр Украины."
            }
        },
        "2": {
            "id": "2",
            "lat": "49.79591",
            "lon": "30.117373",
            "nameObject": {
                "ua": "Біла Церква",
                "en": "Bila Tserkva",
                "ru": "Белая Церковь"
            },
            "aboutObject": {
                "ua": "Місто в Україні, значний економічний, культурний та освітній центр Київщини, розташований за 80 км на південь від Києва на річці Рось. Найбільше місто Київської області. Вперше згадується в Іпатіївському літописі 1115 року як Юр'їв у центральній частині Поросся. Через нього проходили торговельні шляхи на Київ, Вінницю, в арабські країни, Індію, Угорщину.",
                "en": "A city in Ukraine, a significant economic, cultural and educational center of the Kyiv region, located 80 km south of Kyiv on the Ros River. The largest city of Kyiv region. It was first mentioned in the Ipatiev Chronicle in 1115 as Yuryiv in the central part of Porossia. Trade routes to Kyiv, Vinnytsia, Arab countries, India, and Hungary passed through it.",
                "ru": "Город в Украине, значительный экономический, культурный и образовательный центр Киевщины, расположен в 80 км к югу от Киева на реке Рось. Самый большой город Киевской области. Впервые упоминается в Ипатьевской летописи 1115 как Юрьев в центральной части Поросья. Через него проходили торговые пути в Киев, Винницу, в арабские страны, Индию, Венгрию."
            }
        },
        "3": {
            "id": "3",
            "lat": "50.511069",
            "lon": "30.790952",
            "nameObject": {
                "ua": "Бровари",
                "en": "Brovary",
                "ru": "Бровары"
            },
            "aboutObject": {
                "ua": "Місто у Київській області, найбільше місто Київської агломерації після столиці, центр Броварського району та Броварської міської громади. Бровари, ймовірно, засновані близько 1120 року, але вперше згадуються у вірогідних документальних джерелах з 1630 року, статус міста надано в 1956 році. Починаючи від 1970-х років місто має значну промислову базу.",
                "en": "A city in the Kyiv region, the largest city of the Kyiv agglomeration after the capital, the center of the Brovar district and the Brovar city community. Brovary was probably founded around 1120, but was first mentioned in reliable documentary sources from 1630, the status of a town was granted in 1956. Since the 1970s, the city has had a significant industrial base.",
                "ru": "Город в Киевской области, самый большой город Киевской агломерации после столицы, центр Броварского района и Броварской городской общины. Бровары, вероятно, основаны около 1120 года, но впервые упоминаются в достоверных документальных источниках с 1630 года, статус города предоставлен в 1956 году. Начиная с 1970-х годов город имеет значительную промышленную базу."
            }
        },
        "4": {
            "id": "4",
            "lat": "50.350607",
            "lon": "30.95266",
            "nameObject": {
                "ua": "Бориспіль",
                "en": "Boryspil",
                "ru": "Борисполь"
            },
            "aboutObject": {
                "ua": "Місто в Україні, адміністративний центр Бориспільської міської громади та Бориспільського району Київської області. Районний центр з 1923 року, з 1956 по 2020 роки — місто обласного підпорядкування Київської області. Офіційна українська назва міста у першій половині XX століття — Баришпіль. Недалеко від бувшого Баришполя знаходиться містечко Баришівка.",
                "en": "A city in Ukraine, the administrative center of Boryspil city community and Boryspil district of Kyiv region. The district center since 1923, from 1956 to 2020 — a city of regional subordination of Kyiv region. The official Ukrainian name of the city in the first half of the 20th century was Baryshpil. Not far from the former Baryshpol is the town of Baryshivka.",
                "ru": "Город в Украине, административный центр Бориспольского городского общества и Бориспольского района Киевской области. Районный центр с 1923 года, с 1956 по 2020 годы – город областного подчинения Киевской области. Официальное украинское название города в первой половине XX века – Барышполь. Недалеко от бывшего Барышполя находится городок Барышевка."
            }
        },
        "5": {
            "id": "5",
            "lat": "50.51871",
            "lon": "30.239461",
            "nameObject": {
                "ua": "Ірпінь",
                "en": "Irpin",
                "ru": "Ирпень"
            },
            "aboutObject": {
                "ua": "Місто України в передмісті Києва. Розташоване на річці Ірпінь. Центр Ірпінської міської громади. Найбільше місто у Бучанському районі Київської області.",
                "en": "The city of Ukraine in the suburbs of Kyiv. Located on the Irpin River. Center of the Irpin city community. The largest city in Buchansky district of Kyiv region.",
                "ru": "Город Украины в пригороде Киева. Расположен на реке Ирпень. Центр Ирпенской городской общины. Самый большой город в Бучанском районе Киевской области."
            }
        },
        "6": {
            "id": "6",
            "lat": "50.110195",
            "lon": "30.626517",
            "nameObject": {
                "ua": "Обухів",
                "en": "Obukhiv",
                "ru": "Обухов"
            },
            "aboutObject": {
                "ua": "Місто обласного значення в Україні у Київській області, адміністративний центр Обухівського району. Розташоване у долині річки Кобринка на відстані 45 км від міста Києва. До складу Обухівської міської ради входять два населені пункти - село Таценки і село Ленди.",
                "en": "A city of regional significance in Ukraine in the Kyiv region, the administrative center of Obukhiv district. It is located in the valley of the Kobrynka River at a distance of 45 km from the city of Kyiv. The Obukhov City Council includes two settlements - the village of Tatsenky and the village of Lendy.",
                "ru": "Город областного значения в Украине, Киевской области, административный центр Обуховского района. Расположен в долине реки Кобринка в 45 км от города Киева. В состав Обуховского городского совета входят два населенных пункта - село Таценко и село Ленды."
            }
        },
        "7": {
            "id": "7",
            "lat": "50.078405",
            "lon": "29.917113",
            "nameObject": {
                "ua": "Фастів",
                "en": "Fastiv",
                "ru": "Фастов"
            },
            "aboutObject": {
                "ua": "Раніше мав назву Хва́стів — місто в Україні, адміністративний центр Фастівської міської громади та Фастівського району Київської області. Важливий залізничний вузол та промисловий центр.",
                "en": "It used to be called Khvastiv — a city in Ukraine, the administrative center of Fastiv city community and Fastiv district of Kyiv region. An important railway junction and industrial center.",
                "ru": "Раньше назывался Хвастов — город в Украине, административный центр Фастовского городского общества и Фастовского района Киевской области. Важен железнодорожный узел и промышленный центр."
            }
        },
        "8": {
            "id": "8",
            "lat": "50.319432",
            "lon": "29.057028",
            "nameObject": {
                "ua": "Коростишів",
                "en": "Korostyshiv",
                "ru": "Коростышев"
            },
            "aboutObject": {
                "ua": "Місто в Україні в межах Житомирського району Житомирської області. Одне з найдавніших міст України, засноване близько VI-VII століття. За легендою, містечко називалось Хміничі і було центром одного з древлянських племен — мінчан.",
                "en": "A city in Ukraine within Zhytomyr district of Zhytomyr region. One of the oldest cities of Ukraine, founded around the VI-VII centuries. According to legend, the town was called Khminychi and was the center of one of the Drevlyan tribes, the Minskians.",
                "ru": "Город в Украине в пределах Житомирского района Житомирской области. Один из самых древних городов Украины, основанный около VI-VII века. По легенде городок назывался Хминичи и был центром одного из древлянских племен — минчан."
            }
        },
        "9": {
            "id": "9",
            "lat": "50.391896",
            "lon": "30.368358",
            "nameObject": {
                "ua": "Вишневе",
                "en": "Vyshneve",
                "ru": "Вишнёвое"
            },
            "aboutObject": {
                "ua": "Місто в Україні, південно-західне передмістя Києва, центр Вишневої міської громади, адміністративно належить до Бучанського району Київської області.",
                "en": "A city in Ukraine, a southwestern suburb of Kyiv, the center of the Vyshneva urban community, administratively belongs to the Buchansky district of the Kyiv region.",
                "ru": "Город в Украине, юго-западный пригород Киева, центр Вишневой городской общины административно относится к Бучанскому району Киевской области."
            }
        },
        "10": {
            "id": "10",
            "lat": "50.173507",
            "lon": "30.32203",
            "nameObject": {
                "ua": "Васильків",
                "en": "Vasylkiv",
                "ru": "Васильков"
            },
            "aboutObject": {
                "ua": "Місто в Обухівському районі Київської області, розташоване за 20 км на південний захід від Києва на річці Стугна. Центр Васильківської міської громади.",
                "en": "A city in Obukhiv district of Kyiv region, located 20 km southwest of Kyiv on the Stugna River. Center of the Vasylkiv city community.",
                "ru": "Город в Обуховском районе Киевской области, расположен в 20 км юго-западнее Киева на реке Стугна. Центр Васильковской городской общины."
            }
        },
        "11": {
            "id": "11",
            "lat": "50.258174",
            "lon": "28.669387",
            "nameObject": {
                "ua": "Житомир",
                "en": "Zhytomyr",
                "ru": "Житомир"
            },
            "aboutObject": {
                "ua": "Місто на півночі України, розташоване на річці Тетерів. Адміністративний центр Житомирської області та Житомирського району, центр Житомирської міської об'єднаної територіальної громади. Місто поділяється на Богунський та Корольовський райони. Житомир є важливим транспортним вузлом",
                "en": "A city in the north of Ukraine, located on the Teteriv River. Administrative center of Zhytomyr Region and Zhytomyr District, center of Zhytomyr City United Territorial Community. The city is divided into Bohunskyi and Korolevskyi districts. Zhytomyr is an important transport hub",
                "ru": "Город на севере Украины расположен на реке Тетерев. Административный центр Житомирской области и Житомирского района, центр Житомирской городской объединенной территориальной громады. Город делится на Богунский и Королевский районы. Житомир является важным транспортным узлом"
            }
        },
        "12": {
            "id": "12",
            "lat": "49.894731",
            "lon": "28.57993",
            "nameObject": {
                "ua": "Бердичів",
                "en": "Berdychiv",
                "ru": "Бердичев"
            },
            "aboutObject": {
                "ua": "Місто в Україні, адміністративний центр Бердичівської міської територіальної громади та Бердичівського району Житомирській області.",
                "en": "A city in Ukraine, the administrative center of the Berdychiv urban territorial community and the Berdychiv district of the Zhytomyr region.",
                "ru": "Город в Украине, административный центр Бердичевского городского территориального общества и Бердичевского района Житомирской области."
            }
        },
        "13": {
            "id": "13",
            "lat": "50.948045",
            "lon": "28.641317",
            "nameObject": {
                "ua": "Коростень",
                "en": "Korosten",
                "ru": "Коростень"
            },
            "aboutObject": {
                "ua": "Місто в Україні, адміністративний центр Коростенського району та Коростенської міської територіальної громади Житомирської області, залізничний вузол Південно-Західної залізниці. У стародавні часи Іскоростень — столиця міцного союзу племен древлян. У наш час місто славиться своїми цінними породами природного граніту, каніфольними смолами та вишуканою порцеляною.",
                "en": "A city in Ukraine, the administrative center of the Korosten district and the Korosten urban territorial community of the Zhytomyr region, a railway junction of the South-Western Railway. In ancient times, Iskorosten was the capital of a strong union of Drevlian tribes. Nowadays, the city is famous for its valuable natural granite rocks, rosin resins and exquisite porcelain.",
                "ru": "Город в Украине, административный центр Коростенского района и Коростенского городского территориального общества Житомирской области, железнодорожный узел Юго-Западной железной дороги. В древние времена Искоростень – столица крепкого союза племен древлян. В настоящее время город славится своими ценными породами природного гранита, канифольными смолами и изысканным фарфором."
            }
        },
        "14": {
            "id": "14",
            "lat": "50.588698",
            "lon": "27.610896",
            "nameObject": {
                "ua": "Звягель",
                "en": "Zviahel",
                "ru": "Звягель"
            },
            "aboutObject": {
                "ua": "Місто в Житомирській області України. Адміністративний центр Звягельського району та Новоград-Волинської міської громади. Розташований у північно-західній частині області, у місці впадіння річки Смолки до Случі, правої притоки Горині. Вперше згадується у джерелах 1256 року як місто Возвягель.",
                "en": "A city in the Zhytomyr region of Ukraine. The administrative center of the Zvyagel district and the Novohrad-Volyn city community. It is located in the northwestern part of the region, at the confluence of the Smolka River with the Slucha, the right tributary of the Horyn. It was first mentioned in sources in 1256 as the city of Vozviagel.",
                "ru": "Город в Житомирской области. Административный центр Звягельского района и Новоград-Волынского городского общества. Расположен в северо-западной части области, в месте впадения реки Смолки до Случи, правого притока Горыни. Впервые упоминается в источниках 1256 как город Возвягель."
            }
        },
        "15": {
            "id": "15",
            "lat": "51.493966",
            "lon": "31.295042",
            "nameObject": {
                "ua": "Чернігів",
                "en": "Chernihiv",
                "ru": "Чернигов"
            },
            "aboutObject": {
                "ua": "Місто України, обласний центр Чернігівської області. Належить до найдавніших міст України, заснований у кінці VII століття при впаданні річки Стрижень у Десну.",
                "en": "City of Ukraine, regional center of Chernihiv region. Belongs to the oldest cities of Ukraine, founded at the end of the 7th century at the confluence of the Stryzhen River into the Desna.",
                "ru": "Город Украины, областной центр Черниговской области. Принадлежит к древнейшим городам Украины, основанному в конце VII века при впадании реки Стрижень в Десну."
            }
        },
        "16": {
            "id": "16",
            "lat": "51.046939",
            "lon": "31.879916",
            "nameObject": {
                "ua": "Ніжин",
                "en": "Nizhyn",
                "ru": "Нежин"
            },
            "aboutObject": {
                "ua": "Місто в Україні, районний центр Ніжинського району Чернігівської області. Вузол залізничних та автомобільних шляхів, друге за величиною місто обласного значення, що розміщене на берегах річки Остер за 80 км від обласного центру.",
                "en": "A city in Ukraine, the district center of Nizhyn district, Chernihiv region. Junction of railway and road routes, the second largest city of regional significance, located on the banks of the Oster River, 80 km from the regional center.",
                "ru": "Город в Украине – районный центр Нежинского района Черниговской области. Узел железнодорожных и автомобильных путей, второй по величине город областного значения, расположенный на берегах реки Остер в 80 км от областного центра."
            }
        },
        "17": {
            "id": "17",
            "lat": "50.594721",
            "lon": "32.3865",
            "nameObject": {
                "ua": "Прилуки",
                "en": "Pryluky",
                "ru": "Прилуки"
            },
            "aboutObject": {
                "ua": "Місто Чернігівської області, залізничний вузол. Розташоване на півдні Чернігівської області на берегах річки Удай, є адміністративним, економічним і культурним центром Прилуцького району. Має зручне автомобільне та залізничне сполучення з іншими регіонами України.",
                "en": "City of Chernihiv region, railway hub. Located in the south of Chernihiv region on the banks of the Udai River, it is the administrative, economic and cultural center of Prylutsky district. It has convenient road and rail connections with other regions of Ukraine.",
                "ru": "Город Черниговской области, железнодорожный узел. Расположен на юге Черниговской области на берегах реки Удай, является административным, экономическим и культурным центром Прилукского района. Имеет удобное автомобильное и железнодорожное сообщение с другими регионами Украины."
            }
        },
        "18": {
            "id": "18",
            "lat": "51.182635",
            "lon": "32.829724",
            "nameObject": {
                "ua": "Бахмач",
                "en": "Bakhmach",
                "ru": "Бахмач"
            },
            "aboutObject": {
                "ua": "Місто в Чернігівській області України. Входить до Ніжинського району. Через місто проходять такі автотраси - автошлях Т2514, Т2523, Т2531. Розташоване на річці Борзенка (притоці річки Борзна).",
                "en": "A city in the Chernihiv region of Ukraine. It belongs to the Nizhyn district. The following highways pass through the city - highway T2514, T2523, T2531. Located on the Borzenka River (a tributary of the Borzna River).",
                "ru": "Город в Черниговской области. Входит в Нежинский район. Через город проходят следующие автотрассы - автодорога Т2514, Т2523, Т2531. Расположен на реке Борзенко (притоке реки Борзна)."
            }
        }
    }
}