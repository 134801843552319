<template>
    <b-tabs position="is-centered" style="width: 330px;">
        <b-tab-item icon="account" style="max-height: 500px; overflow-y: scroll;">
            <article class="media mb-1">
                <figure class="media-left image is-64x64 m-0 pt-1">
                    <img @click="openPhotoModal()" class="is-rounded" :src="getUserPhoto()"
                        :style="'min-height: 48px; border: 3px solid ' + currentUserColor" />
                </figure>
                <div class="media-content ml-2">
                    <div class="content" style="height: 100%; overflow: hidden">
                        <p class="mb-0">
                            <span v-html="getUserTitle()"></span>
                            <br />
                            <b-icon icon="navigation" style="rotate: 45deg; margin-top: 0.1rem;"
                                :style="'color: ' + currentUserColor">
                            </b-icon>
                            <button @click="isColorPickerOpened = !isColorPickerOpened"
                                class="button is-small is-rounded mr-2 ml-1"
                                :style="'background-color: ' + selectedColor" style="color: white"
                                :disabled="isLoadingColor">
                                <strong class="is-uppercase">{{ selectedColor }}</strong>
                            </button>
                            <i v-if="isLoadingColor" class="mdi mdi-loading mdi-spin"></i>
                            <button :title="tt('BuyColor')" @click="buyColor()"
                                :disabled="(colors[currentUserColor] ? (colors[currentUserColor] * 2) : 10) > user.gold"
                                v-else-if="currentUserColor !== user.color && !isLoadingUser"
                                class="button is-small is-rounded is-success"
                                style="display: inline-block; padding-top: 0.15rem;">
                                <strong style="vertical-align: 30%;">-{{ colors[currentUserColor] ?
                                    (colors[currentUserColor] * 2) : 10 }}</strong>
                                <Emoji utf="💰" size="18px" class="" />
                            </button>
                        </p>
                        <p class="is-size-7 mt-1">{{ tt('BookColorInfo') }}</p>
                    </div>
                </div>
            </article>
            <b-colorpicker v-if="isColorPickerOpened" :value="selectedColor" v-model="selectedColor" inline
                class="mt-2 has-text-centered" />
            <div>
                <div class="has-text-weight-semibold mt-2">
                    <span style="vertical-align: 15%;">
                        {{ tt('Achievements') }}
                    </span>
                    <Emoji utf="🏅" class="" size="20px" />
                    <span style="vertical-align: 15%;">
                        {{ doneAchievementsAmount }} / {{ Object.keys(backmeta.achievements).length * 5 }}
                    </span>
                </div>
                <div :key="achievementsNew.ts ? achievementsNew.ts : 1">
                    <div v-for="(achievement, achievementSlug) in backmeta.achievements" :key="achievementSlug">
                        <div>
                            <span style="vertical-align: 15%;">lvl {{ currentAchievements[achievementSlug].level }} </span>
                            <Emoji :utf="achievement.icon" size="18px" />
                            <strong style="vertical-align: 15%;"> {{ achievement.name[currentLanguage] }}</strong>
                        </div>
                        <b-progress
                            type='is-success'
                            :rounded="true"
                            :max="Number(currentAchievements[achievementSlug].total)"
                            :value="Number(currentAchievements[achievementSlug].value)" 
                            
                            :show-value="true"
                            class="mb-1"
                        >
                            {{ currentAchievements[achievementSlug].value }} / {{ currentAchievements[achievementSlug].total }}
                        </b-progress>
                        <p class="is-size-7 mt-1" v-if="currentAchievements[achievementSlug].text">{{ currentAchievements[achievementSlug].text[currentLanguage] }}</p>
                    </div>
                </div>
            </div>
            <template v-if="user.reputationTotal > 0">
                <div class="has-text-weight-semibold mt-2">
                    <span style="vertical-align: 15%;">
                        {{ tt('InventoryReputation') }}
                        (</span>
                    <Emoji utf="🤝" class="" size="20px" />
                    <span style="vertical-align: 15%;">
                        {{ user.reputationTotal }})
                    </span>
                </div>
                <div>
                    <span v-for="(cityReputation, cityId) in user.reputationObject" :key="cityId" style="line-height: 1;">
                        <span>{{ backmeta.cities[cityId].nameObject[currentLanguage] }} </span>
                        <strong>{{ cityReputation }} </strong>
                    </span>
                </div>
            </template>
        </b-tab-item>
        <b-tab-item icon="cog">
            <b-field label="" type="is-black" :message="tt('CenteredSwitchMessage')">
                <b-switch v-model="navigationCenteredSwitch">
                    <strong>{{ tt('CenteredSwitch') }}</strong>
                </b-switch>
            </b-field>
            <b-field label="screensaver" type="is-black" :message="tt('ScreensaverMessage')">
                <b-radio-button v-model="screensaverDelayRadio" native-value="0">
                    <b-icon icon="close"></b-icon>
                    <span>OFF</span>
                </b-radio-button>
                <b-radio-button v-model="screensaverDelayRadio" native-value="20">
                    <span>20</span>
                </b-radio-button>
                <b-radio-button v-model="screensaverDelayRadio" native-value="40">
                    <span>40</span>
                </b-radio-button>
                <b-radio-button v-model="screensaverDelayRadio" native-value="120">
                    <span>120</span>
                </b-radio-button>
            </b-field>
            <div>
                <p><strong>{{ tt('RefDescription') }}</strong></p>
                <p>{{ tt('RefUserId') }}: <b>{{ user.id }}</b></p>
                <p><i>https://karvan.club/{{ currentLanguage }}/r/{{ user.id }}</i></p>
                <p><i>t.me/KarvanClubBot?start={{ user.id }}</i></p>
            </div>
            <div class="columns is-mobile">
                <div class="column mt-3 mr-2 is-size-5">
                    <a class="mr-4" href="/map">
                        <strong v-if="currentLanguage == 'ua'">UA</strong>
                        <span v-else>UA</span>
                    </a>
                    <a class="mr-4" href="/en/map">
                        <strong v-if="currentLanguage == 'en'">EN</strong>
                        <span v-else>EN</span>
                    </a>
                    <a href="/ru/map">
                        <strong v-if="currentLanguage == 'ru'">RU</strong>
                        <span v-else>RU</span>
                    </a>
                </div>
                <div class="column has-text-right">
                    <button @click="logoutUser()" class="button is-small is-rounded is-warning mt-2">
                        <b-icon icon="logout" title="logout" />
                    </button>
                </div>
            </div>
        </b-tab-item>
        <b-tab-item icon="align-horizontal-left" style="max-height: 500px; overflow-y: scroll;">
            <div v-for="(log, logId) in actionLog" :key="logId" style="line-height: 0.8;" class="mb-1">
                <div v-if="log.action === 'buy'" style="overflow: hidden; white-space: nowrap;" key="buy">
                    <p class="has-text-weight-medium">
                        <Emoji utf="📥" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionItemBuy') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span style="vertical-align: 30%">-{{ log.gold_diff }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                        <Emoji class="mr-1" :utf="goods[log.item_slug].emoji" size="18px" />
                        <span style="vertical-align: 30%">{{ goods[log.item_slug].name[currentLanguage] }}</span>
                        <strong style="vertical-align: 30%"> +{{ log.amount }}</strong>
                    </p>
                </div>
                <div v-else-if="log.action === 'sell'" style="overflow: hidden; white-space: nowrap;" key="sell">
                    <p class="has-text-weight-medium">
                        <Emoji utf="📤" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionItemSell') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span style="vertical-align: 30%">+{{ log.gold_diff }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                        <Emoji class="mr-1" :utf="goods[log.item_slug].emoji" size="18px" />
                        <span style="vertical-align: 30%">{{ goods[log.item_slug].name[currentLanguage] }}</span>
                        <strong style="vertical-align: 30%"> -{{ log.amount }}</strong>
                    </p>
                </div>
                <div v-else-if="log.action === 'whIn'" style="overflow: hidden; white-space: nowrap;" key="whIn">
                    <p class="has-text-weight-medium">
                        <Emoji utf="📦" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionWhIn') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <Emoji class="mr-1" :utf="goods[log.item_slug].emoji" size="18px" />
                        <span style="vertical-align: 30%">{{ goods[log.item_slug].name[currentLanguage] }}</span>
                        <span style="vertical-align: 30%"> {{ log.amount }}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'whOut'" style="overflow: hidden; white-space: nowrap;" key="whOut">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🚘" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionWhOut') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <Emoji class="mr-1" :utf="goods[log.item_slug].emoji" size="18px" />
                        <span style="vertical-align: 30%">{{ goods[log.item_slug].name[currentLanguage] }}</span>
                        <span style="vertical-align: 30%"> {{ log.amount }}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'drive'" style="overflow: hidden; white-space: nowrap;" key="drive">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🚀" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionDrive') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'in_city'" style="overflow: hidden; white-space: nowrap;" key="in_city">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🚘" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ tt('ActionInCity') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'accident'" style="overflow: hidden; white-space: nowrap;" key="accident">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🚨" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionAccident') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <Emoji v-if="log.is_broke" utf="🔩" size="18px" class="mr-1"/>
                        <Emoji v-if="log.out_of_gas" utf="⛽" size="18px" class="mr-1"/>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'evacuate'" style="overflow: hidden; white-space: nowrap;" key="evacuate">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🚨" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionEvacuate') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span style="vertical-align: 30%">-{{ log.price }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'fuel'" style="overflow: hidden; white-space: nowrap;" key="fuel">
                    <p class="has-text-weight-medium">
                        <Emoji utf="⛽" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionFuel') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span style="vertical-align: 30%">-{{ log.price }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                        <strong style="vertical-align: 30%">100%</strong>
                        <Emoji utf="⛽" size="18px" class="mr-1"/>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'repair'" style="overflow: hidden; white-space: nowrap;" key="repair">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🔧️" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionRepair') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span style="vertical-align: 30%">-{{ log.price }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                        <strong style="vertical-align: 30%">100%</strong>
                        <Emoji utf="🔩" size="18px" class="mr-1"/>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'contractApply'" style="overflow: hidden; white-space: nowrap;" key="contractApply">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🤝" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionApply') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span class="has-text-weight-medium" style="vertical-align: 30%;">
                            {{ log.city_id }}.{{ log.contract_id }}
                        </span>
                        <Emoji class="mr-1" utf="🤝" size="18px" />
                        <Emoji class="mr-1" utf="👍" size="18px" />
                    </p>
                </div>
                <div v-else-if="log.action === 'contractDone'" style="overflow: hidden; white-space: nowrap;" key="contractDone">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🏆" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionDone') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span class="has-text-weight-medium" style="vertical-align: 30%;">
                            {{ log.city_id }}.{{ log.contract_id }}
                        </span>
                        <Emoji class="mr-1" utf="🏆" size="18px" />
                        <span style="vertical-align: 30%">+{{ log.prize_rep }}</span>
                        <Emoji class="mr-1" utf="🤝" size="18px" />
                        <span style="vertical-align: 30%">+{{ log.prize_gold }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                    </p>
                </div>
                <div v-else-if="log.action === 'contractBreak'" style="overflow: hidden; white-space: nowrap;" key="contractBreak">
                    <p class="has-text-weight-medium">
                        <Emoji utf="❌" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionBreak') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span class="has-text-weight-medium" style="vertical-align: 30%;">
                            {{ log.city_id }}.{{ log.contract_id }}
                        </span>
                        <Emoji class="mr-1" utf="❌" size="18px" />
                        <span style="vertical-align: 30%">-{{ log.penalty_rep }}</span>
                        <Emoji class="mr-1" utf="🤝" size="18px" />
                        <span style="vertical-align: 30%">-{{ log.penalty_gold }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                    </p>
                </div>
                <div v-else-if="log.action === 'addTransport'" style="overflow: hidden; white-space: nowrap;" key="addTransport">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🚘" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionAddTransport') }} </span>
                        <Emoji :utf="cityIcon" size="18px" class="mr-1" />
                        <span style="vertical-align: 30%">{{ log.city_id }}. {{ backmeta.cities[log.city_id].nameObject[currentLanguage] }}</span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'addGold'" style="overflow: hidden; white-space: nowrap;" key="addGold">
                    <p class="has-text-weight-medium">
                        <Emoji utf="💰" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionAddGold') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span style="vertical-align: 30%" class="has-text-weight-medium">+{{ log.gold }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                    </p>
                </div>
                <div v-else-if="log.action === 'donateGold'" style="overflow: hidden; white-space: nowrap;" key="donateGold">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🍩" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionDonateGold') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span style="vertical-align: 30%" class="has-text-weight-medium">+{{ log.gold }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                    </p>
                </div>
                <div v-else-if="log.action === 'setColor'" style="overflow: hidden; white-space: nowrap;" key="setColor">
                    <p class="has-text-weight-medium">
                        <Emoji utf="💄" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionSetColor') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span style="vertical-align: 30%" class="has-text-weight-medium">-{{ log.price }}</span>
                        <Emoji class="mr-1" utf="💰" size="18px" />
                        <span style="vertical-align: 30%; color: white; border-radius: 10px;" class="has-text-weight-medium p-0 pr-2 pl-1 is-size-6" :style="'background-color: #' + log.color">
                            #{{ log.color }}
                        </span>
                    </p>
                </div>
                <div v-else-if="log.action === 'setStatus'" style="overflow: hidden; white-space: nowrap;" key="setStatus">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🗣️" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionSetStatus') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'achievement'" style="overflow: hidden; white-space: nowrap;" key="achievement">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🏅" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionAchievementDone') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span class="has-text-weight-medium" style="vertical-align: 30%;">lvl {{ log.achievement_level }}</span>
                        <Emoji :utf="backmeta.achievements[log.achievement_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.achievements[log.achievement_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'mechanicAutopilot'" style="overflow: hidden; white-space: nowrap;" key="mechanicAutopilot">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🤖" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionSetAutopilot') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'mechanicChassis'" style="overflow: hidden; white-space: nowrap;" key="mechanicChassis">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🛹" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionSetChassis') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span class="has-text-weight-medium" style="vertical-align: 30%;">+{{ log.coef * 100 }}%</span>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
                <div v-else-if="log.action === 'mechanicTires'" style="overflow: hidden; white-space: nowrap;" key="mechanicTires">
                    <p class="has-text-weight-medium">
                        <Emoji utf="🥾" size="18px" class="mr-1"/>
                        <span style="vertical-align: 30%">{{ tt('ActionSetTires') }} </span>
                    </p>
                    <p>
                        <small style="vertical-align: 38%">{{ (new Date(log.ts * 1000)).toLocaleTimeString('uk-UA') }} </small>
                        <span class="has-text-weight-medium" style="vertical-align: 30%;">+{{ log.coef * 100 }}%</span>
                        <Emoji :utf="backmeta.transport[log.transport_slug].icon" size="18px" />
                        <span class="has-text-weight-medium" style="vertical-align: 30%;"> {{ backmeta.transport[log.transport_slug].name[currentLanguage]}}</span>
                    </p>
                </div>
            </div>
        </b-tab-item>
    </b-tabs>
</template>

<script>
    import { mapState } from 'vuex';
    // import Vue from 'vue';
    import tt from './../plugins/tt.js';

    import Emoji from './../widgets/Emoji.vue';

    export default {
        components: {
            Emoji
        },
        data () {
            return {
                tt: tt,
                currentLanguage: 'ua',
                selectedColor: null,
                currentUserColor: null,
                isLoadingUser: false,
                isLoadingColor: false,
                isColorPickerOpened: false,
                isUserAuth: false,
                editTransportStatus: true,
                navigationCenteredSwitch: false,
                screensaverDelayRadio: null,
                doneAchievementsAmount: 0,
                currentAchievements: {},
            }
        },
        computed: {
            ...mapState({
                backmeta: 'backmeta',
                placeholder: 'placeholder',
                goods: 'ws.Goods',
                cityIcon: 'ws.CityIcon',
                user: 'ws.User',
                actionLog: 'ws.ActionLog',
                achievements: 'ws.Achievements',
                achievementsNew: 'ws.AchievementsNew', // force rerender
                colors: 'ws.Colors',
                isNavigationCentered: 'isNavigationCentered',
                screensaverDelay: 'screensaverDelay',
            })
        },
        watch: {
            user: function () {
                this.isLoadingUser = false;
                this.isLoadingColor = false;
                if (this.user.color !== this.currentUserColor) {
                    this.currentUserColor = this.getUserColor();
                    this.selectedColor = this.currentUserColor;
                }
            },
            selectedColor: function () {
                this.currentUserColor = this.selectedColor.toString().toUpperCase();
            },
            colors: function () {
                this.isLoadingColor = false;
            },
            navigationCenteredSwitch: function () {
                this.$store.commit('setNavigationCentered', this.navigationCenteredSwitch);
            },
            screensaverDelayRadio: function () {
                this.$store.commit('setScreensaverDelay', this.screensaverDelayRadio);
            },
            achievementsNew: function () {
                this.calculateAchievements();
            }
        },
        created () {
            this.currentLanguage = localStorage.getItem('lang') || 'ua';
            this.calculateAchievements();
        },
        mounted () {
            this.navigationCenteredSwitch = this.isNavigationCentered;
            this.screensaverDelayRadio = this.screensaverDelay || '20';
            this.currentUserColor = this.getUserColor();
            this.selectedColor = this.currentUserColor;
            
        },
        methods: {
            calculateAchievements() {
                let doneAchievementsAmount = 0;
                for (let achievementSlug in this.achievements) {
                    this.achievements[achievementSlug].forEach(levelAchievement => {
                        if (levelAchievement.is_done == '1') {
                            doneAchievementsAmount++;
                        }
                    });
                }
                this.doneAchievementsAmount = doneAchievementsAmount;

                for (let achievementSlug in this.backmeta.achievements) {
                    if (this.achievements[achievementSlug] && this.achievements[achievementSlug].length) {
                        this.currentAchievements[achievementSlug] = this.achievements[achievementSlug].at(-1);
                    } else {
                        this.currentAchievements[achievementSlug] = {
                            level: '1',
                            is_done: '0',
                            value: '0',
                        };
                    }
                    this.currentAchievements[achievementSlug].total = this.backmeta.achievements[achievementSlug].levels[this.currentAchievements[achievementSlug].level];
                    if (this.backmeta.achievements[achievementSlug].unique) {
                        this.currentAchievements[achievementSlug].total *= this.backmeta.achievements[achievementSlug].unique;
                    }

                    if (this.backmeta.achievements[achievementSlug].text) {
                        this.currentAchievements[achievementSlug].text = this.backmeta.achievements[achievementSlug].text[this.currentAchievements[achievementSlug]] 
                            ? this.backmeta.achievements[achievementSlug].text[this.currentAchievements[achievementSlug]] 
                            : this.backmeta.achievements[achievementSlug].text['1'];
                    }
                }
            },
            getUserPhoto() {
                const userPhotos = this.user.photosObject || null;
                if (!userPhotos || !userPhotos[0]) {
                    return this.placeholder;
                }

                const cdn = process.env.VUE_APP_CDN_URL || 'https://sultan-cdn.karvan.club';

                return cdn + '/' + userPhotos[0];
            },
            getUserTitle() {
                let userTitle = `<strong>${this.user.first_name} ${this.user.last_name}</strong>`;
                if (!this.user.is_annon) {
                    userTitle += ` <small><a target="_blank" href="https://t.me/${this.user.username}">@${this.user.username}</a></small>`;
                } else {
                    userTitle += ` <small>${this.user.username}</small>`;
                }
                return userTitle;
            },
            openPhotoModal() {
                const cdn = process.env.VUE_APP_CDN_URL || 'https://sultan-cdn.karvan.club';
                const userPhotos = this.user.photosObject || [];
                let photoIndex = 1;

                const img = this.$createElement('img', {
                    ref: 'userPhotoGallery',
                    attrs: {
                        src: userPhotos[0] ? (cdn + '/' + userPhotos[0]) : this.placeholder,
                    },
                    on: {
                        click: () => {
                            this.$refs['userPhotoGallery'].src = userPhotos[photoIndex] ? (cdn + '/' + userPhotos[photoIndex]) : this.placeholder;

                            photoIndex++;
                            if (!userPhotos[photoIndex]) {
                                photoIndex = 0;
                            }
                        }
                    }
                });

                this.$buefy.modal.open({
                    content: [ img ],
                    width: 640
                })
            },
            getUserColor() {
                if (this.user.color) {
                    return this.user.color;
                }

                let userColor = this.stringToColor(this.user.first_name + this.user.last_name);
                if (!this.colors[userColor]) {
                    return userColor;
                }

                let i = 0;
                do {
                    userColor = this.stringToColor(userColor + i);
                    i = i + 1;
                } while (this.colors[userColor] && i < 1000);

                return userColor;
            },
            stringToColor (str) {
                str = 'AAA AAA' + str;

                let hash = 0;
                for (let i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }

                let color = '#';
                for (let i = 0; i < 3; i++) {
                    let value = (hash >> (i * 8)) & 0xFF;
                    color += ('00' + value.toString(16)).substr(-2);
                }

                return color.toUpperCase();
            },
            buyColor() {
                this.isLoadingUser = true;
                this.isLoadingColor = true;
                this.isColorPickerOpened = false;

                this.$wsCall('SetColor', {
                    color: this.currentUserColor,
                    price: this.colors[this.currentUserColor] ? (this.colors[this.currentUserColor] * 2) : 10
                });
            },
            logoutUser () {
                localStorage.removeItem('authToken');
                window.open(this.currentLanguage === 'ua' ? '/' :  ('/' + this.currentLanguage), '_self');
            },
        }
    }
</script>
