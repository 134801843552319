<template>
    <div>
        <l-map 
            ref="myMap"
            :style="'width: 100%; height: ' + windowHeight + 'px'"
            :zoom="mapCurrentZoom"
            :center="mapCurrentCenter"
            :minZoom="mapOptions.minZoom" 
            :maxZoom="mapOptions.maxZoom"
            :options="{
                zoomControl: false,
            }"
        >
            <l-tile-layer
                :url="tileLayerUrl"
                :attribution="tileLayerAttribution"
                :options="tileOptions"
            ></l-tile-layer>

            <l-marker
                v-for="(city, cityId) in backmeta.cities" :key="'cities-' + cityId"
                :ref="'city' + cityId"
                :lat-lng="[parseFloat(city.lat), parseFloat(city.lon)]"
                @click="cityMarkerClickOnMarker(cityId)"
            >
                <l-popup :options="{minWidth: 380}">
                    <City
                        :cityId="cityId"
                        :botUrl="getBotUrl()"
                        :key="'cityElement' + cityId"
                        :showMarketCarousel="cityId === lastOpenCity"
                    />
                </l-popup>
                <l-tooltip :options="{ permanent: true }" v-if="isDebugEnable && isDebugPrice">
                    <p><strong>{{ cityId }}. {{ city.nameObject[currentLanguage] }}</strong></p>
                    <template v-for="(marketItem, marketSlug) in cities[cityId].market">
                        <p :key="marketSlug" v-if="debugGoods.length === 0 || debugGoods.includes(marketSlug)">
                            <span>{{ marketSlug }}: </span>
                            <span v-if="marketItem.sell > 0">+{{ marketItem.sell }} </span>
                            <span v-if="marketItem.buy > 0">-{{ marketItem.buy }}</span>
                        </p>
                    </template>
                </l-tooltip>
            </l-marker>

            <l-marker
                v-for="(item, transportId) in currentTransportMapState"
                :key="'transportMarker' + transportId"
                :ref="transportId"
                :lat-lng="currentTransportMapState[transportId].latLng"
                :icon="getTransportIcon(transportId, item)"
                :options="{
                    transportId: transportId,
                    transportColor: getUserColor(item.user_id),
                }"
                @click="transportMarkerClickOnMarker(transportId)"
            >
                <l-popup
                    :options="{
                        offset: [0, -36],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                        autoClose: false,
                        maxWidth: 350,
                        minWidth: 350,
                    }"
                >
                    <Transport
                        class="is-family-sans-serif"
                        :slug="item.slug"
                        :transportId="transportId"
                        :botUrl="getBotUrl()"
                        :onMap="true"
                    />
                </l-popup>
            </l-marker>

            <l-polyline 
                v-if="displayPath.length > 0"
                :lat-lngs="displayPath" 
                color="#205df7"
                :weight="5"
                :opacity="0.7"
            ></l-polyline>

        </l-map>

        <b-button
            @click="isUserOpen = !isUserOpen"
            style="position: absolute; right: 10px; top: 16px; z-index: 1000;"
            type="is-rounded"
            :class="{ 'is-success': !isUserOpen, 'is-primary': isUserOpen }"
            class="pt-2"
        >
            <span style="vertical-align: 10%;" class="is-size-4" :key="user ? user.gold : null">{{ isUserOpen ? user.gold : getUserGold() }}</span>
            <Emoji utf="💰" size="22px" class="ml-1"/>
        </b-button>
        <div v-if="isUserOpen" class="box p-2" style="position: absolute; right: 10px; top: 70px; z-index: 1000;">
            <User />
        </div>

        <div style="position: absolute; left: 20px; top: 80px; z-index: 1000;">
            <div class="mb-4">
                <b-button @click="zoomReset()" type="is-primary is-light is-rounded is-medium">
                    {{  mapCurrentZoom == mapOptions.minZoom ? 'M' : mapCurrentZoom == mapOptions.maxZoom ? 'm' : mapCurrentZoom }}
                </b-button>
            </div>
            <div class="mb-4">
                <b-button @click="zoomIn()" type="is-primary is-light is-rounded is-medium" :disabled="mapCurrentZoom == mapOptions.maxZoom">
                    <span class="icon">
                        <i class="mdi mdi-18px mdi-plus-thick"></i>
                    </span>
                </b-button>
            </div>
            <div class="mb-4">
                <b-button @click="zoomOut()" type="is-primary is-light is-rounded is-medium" :disabled="mapCurrentZoom == mapOptions.minZoom">
                    <span class="icon">
                        <i class="mdi mdi-18px mdi-minus-thick"></i>
                    </span>
                </b-button>
            </div>
        </div>
        
        <div class="columns has-text-right mb-0" style="position: absolute; right: 10px; bottom: 25px; z-index: 1000;">
            <div v-for="userTransportId in userTransport" class="column" :key="userTransportId">
                <b-button
                    @click="transportMarkerClickOnButton(userTransportId)"
                    :type="getUserTransportButtonType(userTransportId)"
                    class="pt-3"
                    style="transition: 500ms linear;"
                    :class="{
                        'is-warning': (userTransportId in isUserTransportSpeedUp) && isUserTransportSpeedUp[userTransportId],
                        'is-danger is-outlined': (userTransportId in isUserTransportSpeedUp) && !isUserTransportSpeedUp[userTransportId]
                    }"
                >
                    <strong v-if="isKmh" style="vertical-align: 15%;">{{ transport[userTransportId].speed }}</strong>
                    <strong v-else style="vertical-align: 15%;">{{ transport[userTransportId].route_km }}</strong>
                    <Emoji class="ml-2" :utf="backmeta.transport[transport[userTransportId].slug].icon" size="20px" />
                </b-button>
            </div>
            <div class="column">
                <b-button class="is-rounded" :class="{'is-success': !isTransportLoading, 'is-info': isTransportLoading }" @click="clickLoadingButton()">
                    <i v-if="isTransportLoading" class="mdi mdi-loading mdi-spin"></i>
                    <i v-else-if="isScreensaverActive" class="mdi mdi-play"></i>
                    <strong v-else>{{ isKmh ? tt('kmh') : tt('km') }}</strong>
                </b-button>
            </div>
        </div>

        <div
            v-if="selectedTransport && currentTransportMapState[selectedTransport]"
            @click="openCurrentTransportCity()"
            style="position: absolute; left: 10px; bottom: 25px; z-index: 1000;"
        >
            <button
                class="button is-rounded is-light is-info mb-1" style="display: inline-block;"
            >
                <Emoji utf="🚀" size="20px" class="mr-1" />
                <Emoji :utf="cityIcon" size="20px" class="mr-1" />
                <span style="vertical-align: 22%;">
                    {{ currentTransportMapState[selectedTransport].city_id }}.
                    {{ getCityNameFromSelectedTransport() }}
                </span>
                <Emoji :utf="getCityWeatherFromSelectedTransport()?.icon" size="20px" class="ml-1" />
                <span style="vertical-align: 22%;">{{ getCityWeatherFromSelectedTransport()?.temp }}°C</span>
            </button>
            <p class="has-text-centered has-text-primary">
                <span>{{ getEstimatedKm(selectedTransport) }} {{ tt('km') }} </span>
                <strong>~{{ getEstimatedTime(selectedTransport) }}</strong>
            </p>
        </div>

        <b-button v-if="debugToken" @click="isDebugModal = true" style="position: absolute; left: 20px; top: 300px; z-index: 1000;" type="is-danger is-rounded is-medium">
            <b-icon pack="mdi" icon="bug"></b-icon>
        </b-button>
        <b-modal v-if="debugToken" v-model="isDebugModal" :width="640" style="z-index: 1000;">
            <div class="card">
                <div class="card-content">
                    <b-field>
                        <b-switch v-model="isDebugEnable">Is debug enable</b-switch>
                        <b-switch v-model="isDebugStopAnimation">Stop animation</b-switch>
                        <b-switch v-model="isDebugStopTransition">Stop transition</b-switch>
                    </b-field>
                    <b-field>
                        <b-switch v-model="isDebugStopTransportUpdate">Stop transport update</b-switch>
                        <b-switch v-model="isDebugPrice">Is debug price</b-switch>
                    </b-field>

                    <b-tabs position="is-centered">

                        <b-tab-item label="Debug Route">
                            <b-field label="City A">
                                <b-select placeholder="Select from city" v-model="debugCityA">
                                    <option
                                        v-for="(city, cityId) in cities"
                                        :value="cityId"
                                        :key="cityId"
                                    >
                                        {{ city.name }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-field label="City B">
                                <b-select placeholder="Select from city" v-model="debugCityB">
                                    <option
                                        v-for="(city, cityId) in cities"
                                        :value="cityId"
                                        :key="cityId"
                                    >
                                        {{ city.name }}
                                    </option>
                                </b-select>
                            </b-field>
                            <div class="has-text-right">
                                <b-button @click="debugRoute()">Debug route</b-button>
                            </div>
                            <b-field label="JSON path">
                                <b-input v-model="debugJsonPath" type="textarea"></b-input>
                            </b-field>
                        </b-tab-item>

                        <b-tab-item label="Render Route">
                            <b-field label="Route ID">
                                <b-select placeholder="Select route" v-model="debugRouteId">
                                    <option
                                        v-for="(route, routeId) in routes"
                                        :value="routeId"
                                        :key="routeId"
                                    >
                                        {{ routeId }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-field>
                                <b-switch v-model="debugIsToA">Is to A</b-switch>
                            </b-field>
                            <b-button @click="debugRenderRoute()">Render route</b-button>
                        </b-tab-item>

                        <b-tab-item label="Values">
                            <b-field label="Force rotation">
                                <b-input v-model="debugForceRotation"></b-input>
                            </b-field>
                            <b-field label="Goods">
                                <b-taginput
                                    v-model="debugGoods"
                                    ellipsis
                                    icon="label"
                                    placeholder="Add a good"
                                    aria-close-label="Delete this good">
                                </b-taginput>
                            </b-field>
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
        </b-modal>

        <CityModal style="z-index: 1000;" />
        <notifications v-if="isDebugEnable" group="main" position="bottom right" />
    </div>

</template>

<script>
    import { mapState } from 'vuex';
    import Vue from 'vue';
    import L from 'leaflet';
    import { LMap, LTileLayer, LMarker, LPopup, LPolyline, LTooltip } from 'vue2-leaflet';
    import City from './City.vue';
    import CityModal from './CityModal.vue';
    import Transport from './Transport.vue';
    import Emoji from './../widgets/Emoji.vue';
    import User from './User.vue';
    import tt from './../plugins/tt.js';

    export default {
        name: 'Map',

        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            LPolyline,
            LTooltip,
            City,
            CityModal,
            Transport,
            User,
            Emoji,
        },

        data () {
            return {
                L: L,
                tt: tt,
                currentLanguage: 'ua',
                map: null,
                mapCurrentZoom: 12,
                mapCurrentCenter: [50.450114, 30.524172],
                tileLayerUrl: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
                tileLayerAttribution: '&copy; <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                tileOptions: {
                    maxZoom: 19,
                    maxNativeZoom: 19
                },
                windowHeight: window.innerHeight,
                mapOptions: {
                    maxZoom: 19,
                    minZoom: 8,
                },
                isMapMoving: false,
                isUserOpen: false,
                isTransportLoading: true,
                isKmh: true,
                toggledCity: null,
                openedTransport: null,
                clickedTransport: null,
                selectedTransport: null,
                userTransport: [],
                isUserTransportSpeedUp: {},
                editTransportStatus: false,
                transportStatus: '',
                displayPath: [],
                currentTransportMapState: {},
                lastTransportRotation: {},
                transportToDelete: [],
                lastOpenCity: null,
                screensaverDelay: 20,
                screensaverTimer: null,
                screensaverTransport: [],
                isScreensaverActive: false,
                debugToken: null,
                isDebugModal: false,
                isDebugEnable: false,
                isDebugStopAnimation: false,
                isDebugStopTransportUpdate: false,
                isDebugStopTransition: false,
                isDebugPrice: false,
                debugForceRotation: null,
                debugGoods: [],
                debugRouteId: null,
                debugCityA: null,
                debugCityB: null,
                debugJsonPath: null,
                debugIsToA: false,
            }
        },

        computed: {
            ...mapState({
                isLoading: 'isLoading',
                backmeta: 'backmeta',
                gameTick: 'ws.GameTick',
                goods: 'ws.Goods',
                cities: 'ws.Cities',
                cityIcon: 'ws.CityIcon',
                routes: 'ws.Routes',
                transport: 'ws.Transport',
                prevTransport: 'ws.PrevTransport',
                user: 'ws.User',
                users: 'ws.Users',
                openCity: 'ws.OpenCity',
                openTransport: 'ws.OpenTransport',
                colors: 'ws.Colors',
                isNavigationCentered: 'isNavigationCentered',
                screensaverDelayFromStore: 'screensaverDelay',
            })
        },

        watch: {
            isLoading: function (isLoading) {
                this.isTransportLoading = isLoading;
            },

            openCity: function (openCity) {
                if (openCity === null) {
                    return;
                }

                this.displayPath = [];
                this.lastOpenCity = openCity;

                this.$nextTick(() => {
                    if (this.$refs['city' + openCity][0]) {
                        this.$refs['city' + openCity][0].mapObject.openPopup();
                        this.mapCurrentCenter = [
                            this.backmeta.cities[openCity].lat, 
                            this.backmeta.cities[openCity].lon
                        ];
                    }
                    this.$store.commit('openCityOnMap', null);
                });
            },

            transport: function () {
                this.isTransportLoading = false;
                this.tickTransport();
            },
            screensaverDelayFromStore: function (screensaverDelayFromStore) {
                this.screensaverDelay = parseInt(screensaverDelayFromStore);
                this.initScreensaver();
            },
            userTransport: function () {
                let isUserTransportSpeedUp = {};
                this.userTransport.forEach(transportId => {
                    const currentTransportSpeed = parseInt(this.transport[transportId].speed);
                    if (transportId in this.prevTransport) {
                        const prevTransportSpeed = parseInt(this.prevTransport[transportId].speed);
                        isUserTransportSpeedUp[transportId] = currentTransportSpeed >= prevTransportSpeed;
                    }
                });
                this.isUserTransportSpeedUp = isUserTransportSpeedUp;

                setTimeout(() => {
                    this.isUserTransportSpeedUp = {};
                }, 1 * 1000);
            },
        },

        created () {
            const debugToken = localStorage.getItem('debugToken');
            if (debugToken) {
                this.debugToken = debugToken;
            }
            this.currentLanguage = localStorage.getItem('lang') || 'ua';
            if (this.screensaverDelayFromStore) {
                this.screensaverDelay = parseInt(this.screensaverDelayFromStore);
                setTimeout(() => {
                    this.initScreensaver();
                }, this.screensaverDelay * 1000);
            }

            const self = this;
            this.L.Marker.include({
                _setPos: function (pos) {
                    if (this._icon) {
                        if (this.options.transportId) {
                            this._icon.style.zIndex = 1000 + parseInt(this.options.transportId);
                            this._icon.style.transformOrigin = 'center';

                            if (this.options.transportColor) {
                                if (self.clickedTransport === this.options.transportId) {
                                    this._icon.style.animation = 'blink 2s linear infinite';
                                } else {
                                    this._icon.style.animation = '';
                                    this._icon.style.color = this.options.transportColor;
                                }

                                if (self.isDebugEnable) {
                                    this._icon.style.border = '2px solid ' + this.options.transportColor;
                                } else {
                                    this._icon.style.border = '';
                                }
                            }
                        }

                        let rotate = null;
                        if (this.options.transportId) {
                            if (self.currentTransportMapState[this.options.transportId] 
                                && self.currentTransportMapState[this.options.transportId].state === 'accident'
                            ) {
                                rotate = '0deg';
                            } else {
                                const currentRotation = self.lastTransportRotation[this.options.transportId];
                                rotate = currentRotation + 'rad';
                            }
                        }

                        if (L.Browser.any3d) {
                            this._icon.style.transform = 'translate3d(' + pos.x + 'px,' + pos.y + 'px,0)' 
                                + (rotate ? (' rotate(' + rotate + ')') : '');
                        } else {
                            this._icon.style.left = pos.x + 'px';
                            this._icon.style.top = pos.y + 'px';
                            if (rotate) {
                                this._icon.style.transform = 'rotate(' + rotate + ')';
                            }
                        }
                    }

                    if (this._shadow) {
                        L.DomUtil.setPosition(this._shadow, pos);
                    }
                },
            });

            window.addEventListener('resize', () => {
                this.windowHeight = window.innerHeight;
            });

            this.tickTransport();
        },

        mounted () {
            this.$nextTick(() => {
                this.map = this.$refs.myMap.mapObject;

                this.map.setMaxBounds([
                    [52.476089, 26.718926],
                    [49.274973, 33.433144]
                ]);

                this.map.on('zoomstart', () => {
                    // Fix transition when zooming
                    const elements = document.querySelectorAll('.transport-icon, .leaflet-popup');
                    elements.forEach(element => {
                        element.style.transition = 'none';
                    });
                });
                this.map.on('zoomend', () => {
                    const mapZoom = this.map.getZoom();
                    if (mapZoom !== this.mapCurrentZoom) {
                        this.mapCurrentZoom = mapZoom;
                    }

                    // Fix transition when zooming
                    const elements = document.querySelectorAll('.transport-icon, .leaflet-popup');
                    elements.forEach(element => {
                        setTimeout(() => {
                            element.style.transition = '';
                        }, 100);
                    });
                });
                this.map.on('movestart', () => {
                    this.isMapMoving = true;
                });
                this.map.on('moveend', () => {
                    this.isMapMoving = false;
                });

                this.map.on('click', () => {
                    if (this.selectedTransport) {
                        if (this.$refs[this.selectedTransport] && this.$refs[this.selectedTransport][0]) {
                            this.$refs[this.selectedTransport][0].mapObject.closePopup();
                        }

                        this.clickedTransport = this.selectedTransport;
                        this.selectedTransport = null;
                    } else if (this.clickedTransport) {
                        this.clickedTransport = null;
                    }

                    this.initScreensaver();

                    this.displayPath = [];
                });
            });
        },
        destroyed () {
            this.lastTransportRotation = {};
            if (this.screensaverTimer) {
                clearTimeout(this.screensaverTimer);
            }
            this.isScreensaverActive = false;
        },

        methods: {
            tickTransport() {
                if (this.isDebugEnable) {
                    Vue.notify({
                        group: 'main',
                        title: 'tick',
                        text: 'new game tick!'
                    });
                    console.log('! ! ! new game tick!');

                    if (this.isDebugStopTransportUpdate) {
                        console.log('debug skip transport update!');
                        return;
                    }

                    if (this.isDebugStopTransition) {
                        document.querySelectorAll('.transport-icon').forEach(element => {
                            element.style.transition = 'none';
                        });
                    } else {
                        document.querySelectorAll('.transport-icon').forEach(element => {
                            element.style.transition = '';
                        });
                    }
                }

                this.transportToDelete.forEach(transportId => {
                    if (transportId === this.selectedTransport) {
                        this.displayPath = [];
                        this.selectedTransport = null;

                        if (!this.isScreensaverActive) {
                            this.initScreensaver();
                        }
                    }

                    delete this.currentTransportMapState[transportId];
                    delete this.lastTransportRotation[transportId];
                });
                this.transportToDelete = [];

                let userTransport = [];
                for (const transportId in this.transport) {
                    if (this.transport[transportId].user_id === this.user.id) {
                        userTransport.push(transportId);
                    }

                    const transportM = Math.round(this.transport[transportId].route_km * 1000);

                    let currentTransport = this.transport[transportId];
                    let currentLL = null;
                    let prevRouteM = 0;

                    if (['in_city', 'accident'].includes(currentTransport.state)) {
                        currentTransport.isToA = currentTransport.last_direction === 'to_a';
                    } else {
                        currentTransport.isToA = this.isTransportToA(currentTransport); 
                    }

                    if (this.currentTransportMapState[transportId]) {
                        if (currentTransport.state !== this.currentTransportMapState[transportId].state) {
                            if (currentTransport.state !== 'accident' && transportId === this.selectedTransport) {
                                this.displayPath = [];
                            }
                            prevRouteM = transportM;
                        } else {
                            prevRouteM = this.currentTransportMapState[transportId].routeM;
                            if ((transportM - 300) > prevRouteM) {
                                prevRouteM = transportM - 300;
                            }
                        }

                        currentLL = this.getRouteLatLon(currentTransport, prevRouteM);
                    } else {
                        prevRouteM = transportM > 200 ? transportM - 200 : 0;
                        if (currentTransport.state === 'accident') {
                            prevRouteM = transportM;
                        }
                        currentLL = this.getRouteLatLon(currentTransport, prevRouteM);
                    }

                    currentTransport.latLng = [currentLL.lat, currentLL.lon];
                    currentTransport.rotation = currentLL.rotation;
                    currentTransport.prevRouteM = prevRouteM;
                    currentTransport.routeM = transportM;

                    this.currentTransportMapState[transportId] = currentTransport;
                    this.lastTransportRotation[transportId] = this.currentTransportMapState[transportId].rotation;

                    if (this.openTransport && this.openTransport === transportId && this.openTransport !== this.selectedTransport) {
                        if (!this.isScreensaverActive) {
                            this.mapCurrentZoom = 17;
                        }

                        this.$nextTick(() => {
                            this.clickedTransport = transportId;
                            this.transportMarkerClick(transportId, true);
                            this.$store.commit('openTransportOnMap', null);
                        });
                    }

                    if (this.isDebugEnable && this.isDebugStopAnimation) {
                        console.log('debug skip animation!', transportId);
                    } else {
                        if (this.mapCurrentZoom >= 10) { // minimum zoom to animate
                            this.animateTransportMarker(transportId);
                        }
                    }
                }

                this.userTransport = userTransport;

                for (const transportId in this.currentTransportMapState) {
                    if (!this.transport[transportId]) {
                        let currentTransport = this.currentTransportMapState[transportId];
                        const routeDirection = currentTransport.isToA ? 'km_to_a' : 'km_to_a';
                        const transportLastM = Math.round(this.routes[currentTransport.route_id][routeDirection] * 1000);

                        currentTransport.prevRouteM = currentTransport.routeM;
                        currentTransport.routeM = transportLastM;
                        const currentLL = this.getRouteLatLon(currentTransport, currentTransport.prevRouteM);
                        currentTransport.latLng = [currentLL.lat, currentLL.lon];
                        currentTransport.rotation = currentLL.rotation;

                        this.currentTransportMapState[transportId] = currentTransport;
                        this.animateTransportMarker(transportId);
                        this.transportToDelete.push(transportId);
                    }
                }
            },
            zoomIn () {
                if (this.selectedTransport && this.currentTransportMapState[this.selectedTransport]) {
                    this.mapCurrentCenter = this.currentTransportMapState[this.selectedTransport].latLng;
                }

                this.mapCurrentZoom += 1;
            },
            zoomOut () {
                this.mapCurrentZoom -= 1;
            },
            zoomReset () {
                if (this.mapCurrentZoom === this.mapOptions.maxZoom || (this.mapCurrentZoom == this.mapOptions.maxZoom - 2)) {
                    this.mapCurrentZoom = this.mapOptions.minZoom;
                } else {
                    if (this.selectedTransport && this.currentTransportMapState[this.selectedTransport]) {
                        this.mapCurrentCenter = this.currentTransportMapState[this.selectedTransport].latLng;
                    }
                    this.mapCurrentZoom = this.mapOptions.maxZoom - 2;
                }
            },

            isTransportToA(transport) {
                let isToA = transport.state === 'to_a' || transport.state === 'evacuating_to_a';

                return isToA;
            },

            getTransportClasses(transportId, transport) {
                let classes = 'mdi mdi-36px transport-icon transport-icon-transition transport-icon-' + transportId + ' ';

                if (transport.state === 'accident') {
                    classes += 'mdi-alert-octagon';
                } else if (transport.state ==='to_a' || transport.state === 'to_b') {
                    classes += 'mdi-navigation';
                } else {
                    classes += 'mdi-alert-remove';
                }

                return classes;
            },

            getTransportIcon(transportId, transport) {
                return L.divIcon({
                    className: this.getTransportClasses(transportId, transport),
                    iconSize: [36, 36],
                    iconAnchor: [18, 18],
                });
            },

            animateTransportMarker(transportId) {
                const transportrState = this.currentTransportMapState[transportId];
                const prevRouteM = transportrState.prevRouteM;
                const stateDistance = transportrState.routeM - prevRouteM;

                if (transportrState.state === 'accident') {
                    // console.log('animateTransportMarker: accident skip animation!');
                    return;
                }

                if (stateDistance === 0) {
                    // console.log('stateDistance === 0 skip animation!');
                    return;
                }

                const duration = this.gameTick * 1000; // Convert gameTick to milliseconds
                const startTime = performance.now();

                // Smooth animate marker from prevRouteM to routeM
                const animate = (currentTime) => {
                    if (!this.$refs[transportId]
                        || !this.$refs[transportId][0]
                        || prevRouteM !== this.currentTransportMapState[transportId].prevRouteM
                    ) {
                        // console.log('animateTransportMarker: transport not found SKIP');
                        return;
                    }

                    const elapsedTime = currentTime - startTime;
                    const progress = Math.min(elapsedTime / duration, 1); // Calculate progress percentage
                    const routeMWithProgress = Math.round(prevRouteM + stateDistance * progress);

                    const currentLL = this.getRouteLatLon(transportrState, routeMWithProgress);
                    this.lastTransportRotation[transportId] = currentLL.rotation;
                    this.$refs[transportId][0].setLatLng([currentLL.lat, currentLL.lon]); // Update marker position
                    this.boundsToTransport(transportId, [currentLL.lat, currentLL.lon]);

                    if (progress < 1 && currentLL.isLastPoint !== true) {
                        requestAnimationFrame(animate); // Continue animation until progress reaches 1
                    }
                };
                requestAnimationFrame(animate); // Start the animation
            },

            transportMarkerClickOnButton (transportId) {
                this.transportMarkerClick(transportId, true);
                if (this.toggledCity) {
                    if (!this.selectedTransport) {
                        this.toggledCity = null;
                    } else {
                        this.toggleCityPopup(this.toggledCity);
                    }
                }
                this.initScreensaver();
            },
            transportMarkerClickOnMarker (transportId) {
                this.clickedTransport = transportId;
                this.transportMarkerClick(transportId);
                this.initScreensaver();
            },
            transportMarkerClick (transportId, isFixTransition = false) {
                const transport = this.currentTransportMapState[transportId];
                if (this.selectedTransport && this.$refs[this.selectedTransport] && this.$refs[this.selectedTransport][0]) {
                    this.$refs[this.selectedTransport][0].mapObject.closePopup();
                }
                if (isFixTransition && (
                    (this.selectedTransport !== transportId && this.clickedTransport !== transportId)
                    || this.openTransport === transportId)
                ) {
                    document.querySelectorAll('.transport-icon').forEach(element => {
                        element.style.transition = 'none';
                    });
                    setTimeout(() => {
                        document.querySelectorAll('.transport-icon').forEach(element => {
                            element.style.transition = '';
                        });
                    }, 100);
                }

                if (this.clickedTransport === transportId) {
                    this.selectedTransport = transportId;
                    this.clickedTransport = null;
                } else {
                    this.clickedTransport = transportId;
                    this.selectedTransport = null;
                }

                if (this.selectedTransport === transportId) {
                    const transportPath = transport.isToA ? this.routes[transport.route_id].path_to_a.path : this.routes[transport.route_id].path_to_b.path;
                    this.displayPath = transportPath;
                    this.openedTransport = transportId;

                    if (isFixTransition) {
                        this.$refs[transportId][0].mapObject.openPopup();
                    }
                } else {
                    this.openedTransport = null;
                    this.displayPath = [];
                }

                if (!this.currentTransportMapState[transportId]) {
                    return;
                }

                this.mapCurrentCenter = this.currentTransportMapState[transportId].latLng;
            },
            getUserTransportButtonType(transportId) {
                if (transportId === this.selectedTransport) {
                    return 'is-primary is-rounded';
                }

                if (transportId === this.clickedTransport) {
                    return 'is-success is-rounded';
                }

                return 'is-primary is-rounded ' + (transportId in this.isUserTransportSpeedUp ? '' : 'is-light');
            },
            boundsToTransport(transportId, currentTransportLatLng) {
                if (!this.map || this.selectedTransport !== transportId || this.isMapMoving || this.isTransportLoading) {
                    return;
                }

                const currentMapBounds = this.map.getBounds();
                if (currentMapBounds.contains(currentTransportLatLng)) {
                    return;
                }

                if (this.isNavigationCentered) {
                    this.mapCurrentCenter = currentTransportLatLng;
                    return;
                }

                const pixelBounds = this.map.getPixelBounds();
                const currentPoint = this.map.project(currentTransportLatLng, this.map.getZoom());

                let newBounds = [];
                const mapWidth = pixelBounds.max.x - pixelBounds.min.x;
                const mapHeight = pixelBounds.max.y - pixelBounds.min.y;

                if (currentTransportLatLng[0] > currentMapBounds.getNorth()) {
                    // If the transport is hitting the top border, make it appear from the bottom
                    newBounds = [
                        [
                            currentPoint.x - mapWidth / 2,
                            currentPoint.y - mapHeight
                        ], 
                        [
                            currentPoint.x + mapWidth / 2,
                            currentPoint.y
                        ]
                    ];
                } else if (currentTransportLatLng[0] < currentMapBounds.getSouth()) {
                    // If the transport is hitting the bottom border, make it appear from the top
                    newBounds = [
                        [
                            currentPoint.x - mapWidth / 2,
                            currentPoint.y
                        ], 
                        [
                            currentPoint.x + mapWidth / 2,
                            currentPoint.y + mapHeight
                        ]
                    ];
                } else if (currentTransportLatLng[1] > currentMapBounds.getEast()) {
                    // If the transport is hitting the right border, make it appear from the left
                    newBounds = [
                        [
                            currentPoint.x,
                            currentPoint.y - mapHeight / 2
                        ],
                        [
                            currentPoint.x + mapWidth,
                            currentPoint.y + mapHeight / 2
                        ]
                    ];
                } else if (currentTransportLatLng[1] < currentMapBounds.getWest()) {
                    // If the transport is hitting the left border, make it appear from the right
                    newBounds = [
                        [
                            currentPoint.x - mapWidth,
                            currentPoint.y - mapHeight / 2
                        ],
                        [
                            currentPoint.x,
                            currentPoint.y + mapHeight / 2
                        ]
                    ];
                }

                const newBoundsLatLng = [
                    this.map.unproject(newBounds[0], this.map.getZoom()),
                    this.map.unproject(newBounds[1], this.map.getZoom())
                ];
                // L.rectangle(newBoundsLatLng, {color: "#ff7800", weight: 1}).addTo(this.map);

                const elements = document.querySelectorAll('.transport-icon, .leaflet-popup');
                elements.forEach(element => {
                    element.style.transition = 'none';
                    setTimeout(() => {
                        element.style.transition = '';
                    }, 100);
                });

                const newCenter = L.latLngBounds(newBoundsLatLng).getCenter();
                this.mapCurrentCenter = [newCenter.lat, newCenter.lng];
            },
            clickLoadingButton () {
                this.isKmh = !this.isKmh;

                this.initScreensaver();
            },
            
            getRouteLatLon(transport, specificRouteM) {
                const routeId = transport.route_id;
                const routeM = specificRouteM;
                const to = transport.isToA ? 'path_to_a' : 'path_to_b';
                const routeStepsCount = this.routes[routeId][to].path.length;

                if (routeM === 0) {
                    // console.log('routeM === 0 skip path iteration!');

                    return {
                        lat: this.routes[routeId][to].path[0][0],
                        lon: this.routes[routeId][to].path[0][1],
                        rotation: this.getRotation(
                            this.routes[routeId][to].path[0][0],
                            this.routes[routeId][to].path[0][1],
                            this.routes[routeId][to].path[1][0],
                            this.routes[routeId][to].path[1][1]
                        )
                    };
                }
                
                for (let i = 0; i < routeStepsCount - 1; i++) {
                    if (this.routes[routeId][to].path[i][2] > routeM) {
                        let prevDistance = 0;
                        let distanceToNext = 0;
                        if (i === 0) {
                            distanceToNext = this.routes[routeId][to].path[i][2];
                        } else {
                            distanceToNext = this.routes[routeId][to].path[i][2] - this.routes[routeId][to].path[i - 1][2];
                            prevDistance = this.routes[routeId][to].path[i - 1][2];
                        }

                        const currentPoint = this.routes[routeId][to].path[i];
                        const nextPoint = this.routes[routeId][to].path[i + 1];
                        const deltaLat = (nextPoint[0] - currentPoint[0]) / distanceToNext;
                        const deltaLon = (nextPoint[1] - currentPoint[1]) / distanceToNext;

                        const distanceM = routeM - prevDistance;
                        const lat = currentPoint[0] + deltaLat * distanceM;
                        const lon = currentPoint[1] + deltaLon * distanceM;
                        let newRotation = this.getRotation(currentPoint[0], currentPoint[1], nextPoint[0], nextPoint[1]);

                        let diff = newRotation - this.lastTransportRotation[transport.id];
                        if (diff > Math.PI) {
                            newRotation -= 2 * Math.PI;
                        } else if (diff < -Math.PI) {
                            newRotation += 2 * Math.PI;
                        }

                        return {
                            lat: lat,
                            lon: lon,
                            rotation: newRotation
                        };
                    }
                }

                // console.log('routeM is more than routeStepsCount, return last point!');

                return {
                    isLastPoint: true,
                    lat: this.routes[routeId][to].path[routeStepsCount - 1][0],
                    lon: this.routes[routeId][to].path[routeStepsCount - 1][1],
                    rotation: this.getRotation(
                        this.routes[routeId][to].path[routeStepsCount - 2][0],
                        this.routes[routeId][to].path[routeStepsCount - 2][1],
                        this.routes[routeId][to].path[routeStepsCount - 1][0],
                        this.routes[routeId][to].path[routeStepsCount - 1][1]
                    )
                };
            },

            getDistanceFromLatLonInKm(latA, lonA, latB, lonB) {
                const R = 6371; // Radius of the earth in km
                const dLat = this.toRadians(latB - latA);  // deg2rad below
                const dLon = this.toRadians(lonB - lonA);

                const a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(this.toRadians(latA)) * Math.cos(this.toRadians(latB)) *
                    Math.sin(dLon / 2) * Math.sin(dLon / 2)
                ;
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

                return R * c; // Distance in km
            },
            getRotation (latA, lonA, latB, lonB) {
                const startLat = this.toRadians(latA);
                const startLng = this.toRadians(lonA);
                const destLat = this.toRadians(latB);
                const destLng = this.toRadians(lonB);

                const y = Math.sin(destLng - startLng) * Math.cos(destLat);
                const x = Math.cos(startLat) * Math.sin(destLat) -
                    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);

                let rotation = Math.atan2(y, x);
                
                return rotation;
            },
            toRadians(degrees) {
                return degrees * Math.PI / 180;
            },
            toDegrees(radians) {
                return radians * 180 / Math.PI;
            },

            getUserPhoto(userId) {
                const placeholder = 'https://bulma.io/images/placeholders/96x96.png';
                const user = this.users[userId];
                if (!user || !user.photos) {
                    return placeholder;
                }

                const photo = user.photos.split("\n")[0] || null;
                if (!photo) {
                    return placeholder;
                }

                const cdn = process.env.VUE_APP_CDN_URL || 'https://cdn.karvan.club';

                return cdn + '/' + photo;
            },
            getBotUrl () {
                let botUrl = 'https://t.me/KarvanSultanBot';
                const refId = parseInt(localStorage.getItem('r'));
                if (refId) {
                    botUrl += '?start=' + refId;
                }

                return botUrl;
            },
            callEditStatus (transportId) {
                this.$wsCall('SetStatus', {
                    transportId: transportId,
                    status: this.transportStatus
                });

                this.editTransportStatus = false;
            },
            getUserGold () {
                if (this.isLoading) {
                    return '. . .';
                }

                if (this.user.gold >= 1000 && this.user.gold < 1000000) {
                    return this.roundDown(this.user.gold / 1000, 2) + 'k';
                } else if (this.user.gold >= 1000000) {
                    return this.roundDown(this.user.gold / 1000000, 2) + 'm';
                }

                return this.user.gold;
            },
            roundDown(number, precision) {
                precision = precision || 0;
                return (Math.floor(number * Math.pow(10, precision)) / Math.pow(10, precision));
            },

            debugRoute () {
                if (!this.debugToken) {
                    return;
                }

                // Define the API endpoint URL
                const apiUrl = "https://api.openrouteservice.org/v2/directions/driving-car";

                // Define the API key
                const apiKey = this.debugToken;

                // Define the start and end coordinates
                const start = this.cities[this.debugCityA].lon + ',' + this.cities[this.debugCityA].lat;
                const end = this.cities[this.debugCityB].lon + ',' + this.cities[this.debugCityB].lat;

                // Construct the request URL
                const requestUrl = `${apiUrl}?api_key=${apiKey}&start=${start}&end=${end}`;

                // Make the API request using fetch
                fetch(requestUrl)
                    .then(response => {
                        // Check if the response is successful
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        // Parse the JSON response
                        return response.json();
                    })
                    .then(data => {
                        // Handle the response data
                        console.log("Directions data:", data);
                        // You can access various information from the response here

                        // console.log(data.features[0].geometry.coordinates);
                        console.log(data.features[0].properties.summary.distance);
                        
                        const coords = data.features[0].geometry.coordinates.map(arr => {
                            return [arr[1], arr[0], ...arr.slice(2)];
                        });

                        this.displayPath = coords;

                        let distance = 0;
                        const coordsWithDistance = coords.map((arr, index) => {
                            if (index < coords.length - 1) {
                                distance += Math.round(this.getDistanceFromLatLonInKm(arr[0], arr[1], coords[index + 1][0], coords[index + 1][1]) * 1000);
                                return [arr[0], arr[1], distance, ...arr.slice(2)];
                            }
                            return arr;
                        });

                        this.debugJsonPath = JSON.stringify({
                            distance: distance,
                            path: coordsWithDistance
                        });
                    })
                    .catch(error => {
                        // Handle any errors that occurred during the fetch
                        console.error("Error fetching directions:", error);
                    });
                
            },
            debugRenderRoute () {
                if (this.debugIsToA) {
                    this.displayPath = this.routes[this.debugRouteId].path_to_a.path;
                    console.log('Total distance (m): ', this.routes[this.debugRouteId].path_to_a.distance);
                } else {
                    this.displayPath = this.routes[this.debugRouteId].path_to_b.path;
                    console.log('Total distance (m): ', this.routes[this.debugRouteId].path_to_b.distance);
                }
            },

            getUserColor(userId) {
                const user = this.users[userId];
                if (!user) {
                    return this.stringToColor(userId);
                }

                if (user.color) {
                    return user.color;
                }

                let userColor = this.stringToColor(user.first_name + user.last_name);
                if (!this.colors[userColor]) {
                    return userColor;
                }

                let i = 0;
                do {
                    userColor = this.stringToColor(userColor + i);
                    i = i + 1;
                } while (this.colors[userColor] && i < 1000);

                return userColor;
            },
            stringToColor (str) {
                str = 'AAA AAA' + str;

                let hash = 0;
                for (let i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }

                let color = '#';
                for (let i = 0; i < 3; i++) {
                    let value = (hash >> (i * 8)) & 0xFF;
                    color += ('00' + value.toString(16)).substr(-2);
                }

                return color.toUpperCase();
            },
            getCityFromSelectedTransport () {
                if (this.selectedTransport && this.currentTransportMapState[this.selectedTransport]) {
                    return this.cities[this.currentTransportMapState[this.selectedTransport].city_id];
                }

                return null;
            },
            getCityNameFromSelectedTransport () {
                return this.backmeta.cities[this.currentTransportMapState[this.selectedTransport].city_id].nameObject[this.currentLanguage];
            },
            getCityWeatherFromSelectedTransport () {
                return this.getCityFromSelectedTransport()?.weather;
            },
            getEstimatedKm(transportId) {
                const transport = this.currentTransportMapState[transportId];
                const route = this.routes[transport.route_id];
                let kmToDestination = (transport.isToA ? route['km_to_a'] : route['km_to_b']) - transport['route_km'];

                return kmToDestination.toFixed(3);
            },
            getEstimatedTime(transportId) {
                const transport = this.currentTransportMapState[transportId];
                if (Number(transport['speed']) === 0) {
                    return '';
                }

                const driveTime = Number(this.getEstimatedKm(transportId)) / Number(transport['speed']);
                let hours = parseInt(driveTime);
                let minutes = parseInt((driveTime % 1) * 60);

                if (hours   < 10) { hours   = "0"+hours; }
                if (minutes < 10) { minutes = "0"+minutes; }

                return hours + ':' + minutes;
            },
            getRouteKm(transportId) {
                const transport = this.currentTransportMapState[transportId];

                let direction = 'km_to_b';
                if (this.isTransportToA(transport)) {
                    direction = 'km_to_a';
                }

                return Number(this.routes[transport.route_id][direction]);
            },

            openCurrentTransportCity () {
                if (this.selectedTransport) {
                    if (this.$refs[this.selectedTransport]) {
                        this.$refs[this.selectedTransport][0].mapObject.closePopup();
                    }

                    this.toggleCityPopup(this.currentTransportMapState[this.selectedTransport].city_id);
                    this.initScreensaver();
                }
            },
            toggleCityPopup (cityId) {
                const popup = this.$refs['city' + cityId][0];
                if (popup.mapObject.isPopupOpen()) {
                    popup.mapObject.closePopup();
                    this.toggledCity = null;
                } else {
                    this.lastOpenCity = cityId;
                    this.selectedTransport = null;
                    this.displayPath = [];

                    popup.mapObject.openPopup();
                    this.mapCurrentCenter = [this.backmeta.cities[cityId].lat, this.backmeta.cities[cityId].lon];
                    this.toggledCity = cityId;
                }
            },
            cityMarkerClickOnMarker(cityId) {
                const popup = this.$refs['city' + cityId][0];
                if (!popup.mapObject.isPopupOpen()) {
                    this.lastOpenCity = cityId;
                }
            },

            initScreensaver () {
                if (this.screensaverTimer) {
                    clearTimeout(this.screensaverTimer);
                }
                this.isScreensaverActive = false;

                if (this.screensaverDelay === 0 || this.selectedTransport) {
                    return;
                }

                this.screensaverTimer = setTimeout(() => {
                    this.clickedTransport = null;
                    this.runScreensaver();
                }, this.screensaverDelay * 1000);
            },

            runScreensaver () {
                this.isScreensaverActive = true;

                const transportId = this.screensaverTransport.pop();
                if (transportId && this.currentTransportMapState[transportId]) {
                    this.$store.commit('openTransportOnMap', transportId);
                } else {
                    if (this.$refs[this.selectedTransport]) {
                        this.$refs[this.selectedTransport][0].mapObject.closePopup();
                    }
                    this.selectedTransport = null;

                    let cities = Object.keys(this.cities);
                    this.shuffleArray(cities);

                    this.$store.commit('openTransportOnMap', null);
                    this.$store.commit('openCityOnMap', cities.pop());

                    let transport = Object.keys(this.transport);
                    this.shuffleArray(transport);
                    this.screensaverTransport = transport;
                }

                this.screensaverTimer = setTimeout(() => {
                    this.runScreensaver();
                }, this.screensaverDelay * 1000);
            },

            shuffleArray (array) {
                let currentIndex = array.length;

                // While there remain elements to shuffle...
                while (currentIndex != 0) {

                    // Pick a remaining element...
                    let randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;

                    // And swap it with the current element.
                    [array[currentIndex], array[randomIndex]] = [
                        array[randomIndex], array[currentIndex]];
                }
            },
        },

    }
</script>

<style scoped>

</style>
